import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import _ from 'lodash';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'LoginPage',
        component: () => import('@/views/LoginPage.vue')
    },
    {
        path: '/home',
        name: 'HomePage',
        component: () => import('@/views/HomePage.vue')
    },
    {
        path: '/logout',
        name: 'LogoutPage',
        component: () => import('@/views/LogoutPage.vue')
    },
];

export default createRouter({
    history: createWebHistory(),
    routes,
});

