export default {
  "LOGIN_PAGE": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiez-vous"])},
    "ERROR_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les identifiants ne correspondent à aucun compte"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre email"])},
    "PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe"])}
  },
  "CGS": {
    "BEGINNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En continuant, vous acceptez la"])},
    "LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["politique de confidentialité"])},
    "END": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de Meelo"])}
  },
  "IDENTITY": {
    "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "FIRSTNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "BIRTHDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
    "PLACE_OF_BIRTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de naissance"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse mail"])},
    "PHONE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portable"])},
    "LASTNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])}
  },
  "ADDRESS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "NUMBER_AND_STREET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° et nom de rue"])},
    "ZIP_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
    "CITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])}
  },
  "COMPANY": {
    "LIQUIDATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidation"])},
    "RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radiation"])},
    "SIREN_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro SIREN"])},
    "INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de l'entreprise"])},
    "CREATED_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création"])},
    "LEGAL_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison sociale"])},
    "CAPITAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital social"])},
    "IDENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identité de l'entreprise"])},
    "BUSINESS_OWNERS_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des bénéficiaires effectifs"])},
    "BUSINESS_OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirigeant"])},
    "MOTHBALLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise en sommeil"])},
    "CURRENT_PROCEDURES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procédures en cours"])},
    "E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-réputation"])},
    "GLOBAL_NOTE_E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note globale"])},
    "ADVICE_NOTE_E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'avis"])},
    "WEBSITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site web"])},
    "NUMBER_ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'établissements"])},
    "REPRESENTATIVES_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des représentants"])},
    "RECOMMENDED_OUTSTANDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encours recommandé"])},
    "TYPE_PERSON": {
      "NATURAL_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne physique"])},
      "LEGAL_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne morale"])}
    },
    "FUNCTIONS": {
      "PRESIDENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Président"])},
      "CHAIRMAN_OF_THE_BOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Président du conseil d'administration"])},
      "CHAIRMAN_AND_CHIEF_EXECUTIVE_OFFICER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Président directeur Général"])},
      "MANAGING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérant"])},
      "NON_ASSOCIATE_MANAGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérant non associé"])},
      "CO_MANAGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co-gérant"])},
      "MANAGING_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur général"])},
      "GENERAL_MANAGER_NOT_A_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur général non administrateur"])},
      "SOLE_MANAGING_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur général unique"])},
      "CHIEF_OPERATING_OFFICER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur général délégué"])},
      "VICE_CHAIRMAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vice-président"])},
      "DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrateur"])},
      "DEPUTY_ADMINISTRATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrateur délégué"])},
      "CHAIRMAN_OF_THE_EXECUTIVE_DIRECTORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Président du directoire"])},
      "CHAIRMAN_OF_THE_SUPERVISORY_BOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Président du conseil de surveillance"])},
      "MANAGING_PARTNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associé-gérant"])},
      "CONTROLLER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôleur de gestion"])},
      "STATUTORY_AUDITOR_TITULAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissaire aux comptes - titulaire"])},
      "STATUTORY_AUDITOR_DEPUTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissaire aux comptes - suppléant"])},
      "STATUTORY_AUDITOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissaire aux comptes"])},
      "LIQUIDATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquitadeur"])},
      "PROVISIONAL_ADMINISTRATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrateur provisoire"])},
      "REPRESENTATIVE_IN_FRANCE_OF_A_FOREIGN_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Représentant en France d'une société étrangère"])},
      "MEMBER_OF_THE_BOARD_OF_DIRECTORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membre du comité d'administration"])},
      "CHAIRMAN_OF_THE_BOARD_OF_DIRECTORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Président du comité d'administration"])}
    },
    "TYPE": {
      "SOCIETY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Societé"])},
      "COLLECTIVE_INVESTMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placement collectif"])},
      "GIE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe d'intérêt économique"])}
    }
  },
  "BUTTONS": {
    "VALIDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
    "VERIFY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier"])},
    "CONNEXION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
    "CONFIRM_INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer les informations"])},
    "DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])},
    "VERIFY_IDENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier l'identité"])},
    "START_OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer l'Openbanking"])},
    "JOURNEY_OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faire le parcours Open Banking sur le navigateur"])},
    "FINISH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminer"])},
    "EXPORT_PDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger la synthèse"])},
    "PREVIOUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédent"])}
  },
  "APPLICANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demandeur"])},
  "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
  "VERIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vérifié"])},
  "MAIL_ACCOUNTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comptes liés au mail"])},
  "PHONE_ACCOUNTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comptes liés au téléphone"])},
  "APPLICANT_RESULTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats du demandeur"])},
  "CONTACT_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordonnées"])},
  "LINK_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien avec l'entreprise"])},
  "IDENTITY_CONSISTENCY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cohérence de l'identité"])},
  "OTHER_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre personne"])},
  "SITUATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situation"])},
  "NOT_FOUND": {
    "NO_BUSINESS_OWNERS_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucuns bénéficiaires effectifs récupérés..."])},
    "NO_LEADERS_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucuns dirigeants récupérés..."])}
  },
  "OPEN_BANKING": {
    "END_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les données ont bien été transmises. Vous pouvez fermer cette page pour voir le résultat de l'analyse."])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Banking"])},
    "DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Banking détails"])},
    "BANK_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banque"])},
    "INCOMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrées"])},
    "OUTCOMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorties"])},
    "HOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titulaire"])},
    "ACCOUNT_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du compte"])},
    "MONTH_SALARIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salaire mensuel moyen"])},
    "MONTH_RENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loyer mensuel moyen"])},
    "BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde actuel"])},
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci de demander à votre client de se munir de son smartphone et ses coordonnées bancaires."])},
    "CATEGORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
    "CATEGORIES": {
      "MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant maximal de transactions"])},
      "MONTH_MAX_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de la transaction maximale"])},
      "MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant moyen mensuel total"])},
      "MONTH_SALARIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant moyen mensuel - Salaires"])},
      "MONTH_SALARIES_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date moyenne mensuelle - Salaires"])},
      "MONTH_INTERESTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant moyen mensuel - Intérêts"])},
      "MONTH_TRANSFERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant moyen mensuel - Virements"])},
      "MONTH_RENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant moyen mensuel - Loyers"])},
      "MONTH_WITHDRAWALS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant moyen mensuel - Retraits distributeurs"])},
      "MONTH_INSURANCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant moyen mensuel - Assurance"])},
      "MONTH_UTILITIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant moyen mensuel - Services publiques"])},
      "MONTH_GROCERIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant moyen mensuel - Achats alimentaires"])},
      "MONTH_FOOD_CONSUMPTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant moyen mensuel - Consommation alimentaire"])},
      "MONTH_SHOPPINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant moyen mensuel - Achats"])},
      "MONTH_HEALTH_WELLNESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant moyen mensuel - Santé/bien-être"])},
      "MONTH_TRANSPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant moyen mensuel - Transports"])},
      "MONTH_TELECOMMUNICATION_SUBSCRIPTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant moyen mensuel - Abonnements liés aux services de télécommunication"])},
      "MONTH_AUDIO_VISUAL_SUBSCRIPTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant moyen mensuel - Abonnements liés aux services de l'audio-visuel"])}
    },
    "DEBT_RATIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux d'endettement"])},
    "REMAINS_TO_LIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reste à Vivre"])},
    "START_STUDY_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début de la période d'analyse financière"])},
    "END_STUDY_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin de la période d'analyse financière"])},
    "MONTH_END_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance mensuelle"])},
    "TOP_JOINT_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Compte Joint"])},
    "TOP_RECOMMENDED_DATE_DEBIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date recommandée de débit"])},
    "TOP_BANKING_SUPERVISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervision bancaire"])},
    "GLOBAL_TRANSACTIONS_COUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de transactions"])}
  },
  "SOCIAL_FACE_MATCHING": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social FaceMatching"])},
    "CATCHED_IMAGES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images récupérées"])}
  },
  "AMLCFT": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lutte contre le blanchiment et le terrorisme"])},
    "FREEZING_ASSETS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gel des avoirs"])},
    "PPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne politiquement exposé"])},
    "ADVERSE_MEDIA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presse négative"])},
    "COLUMN_TABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LCBFT"])}
  },
  "TOOLTIP": {
    "CURRENT_PROCEDURES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société ayant une procédure judiciaire en cours."])},
    "MOTHBALLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société mise en sommeil et réactivée depuis."])},
    "MOBILE_PHONE_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification du téléphone mobile."])},
    "SOCIAL_FACE_MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matching entre les images disponibles en ligne et la pièce d'identité."])},
    "AVALIABLES_PICTURES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images disponibles sur les réseaux sociaux par rapport aux informations du demandeur."])},
    "NUMBER_ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'établissements détenus par la société"])},
    "FREEZING_ASSETS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui si il y a un gel des avoirs en cours."])},
    "SCORE_WEB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoring Fraude sur l'existence et la fiabilité des données de contact du demandeur."])},
    "SCORE_SECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoring Risque sur l'analyse financière et la solvabilité de l'entreprise."])},
    "DEFAULT_PROBABILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesure du risque de défaillance de la structure."])},
    "SCORE_LAST_UPDATE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scores mis à jour le"])},
    "SCORE_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le score évalue la solidité financière de l'entreprise, en fonction de son secteur d'activité et de ses bilans financiers."])},
    "SCORE_OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoring Risque sur l'analyse financière du compte bancaire."])},
    "MONTH_END_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance mensuelle moyenne sur la période étudiée."])}
  },
  "DOCUMENT_CONTROL": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôler les documents"])},
    "CHOOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cocher le document que vous voulez télécharger :"])},
    "IDENTITY_CARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte d'identité"])},
    "DRIVING_LICENSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permis de conduire"])},
    "PASSPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passeport"])},
    "BLOC_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôle des documents"])},
    "ERROR_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'analyse de la pièce a échoué"])},
    "TABLE": {
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document"])},
      "COMPLIANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conformité"])},
      "RESULT": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats d'analyse de votre document"])},
        "CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification"])},
        "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats"])}
      }
    }
  },
  "SCORE": {
    "SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
    "WEB_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web"])},
    "SECTOR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secteur"])},
    "COMPANY_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise"])},
    "DEFAULT_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Défaut"])}
  },
  "FINANCIAL_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse financière"])},
  "TAX_REPORTS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liasse fiscale"])},
    "FISCAL_PERIOD_DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée d'exercice"])},
    "CLOSURE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de clôture"])},
    "TURNOVER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiffre d'affaire"])},
    "NET_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat net"])},
    "AUTO_FINANCE_CAPACITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacité d'auto-financement"])},
    "NET_MARGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marge nette"])},
    "FB_TB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Structure financière"])},
    "FINANCIAL_LIQUIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibilité"])},
    "WORKING_CAPITAL_REQUIREMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin de fonds de roulement"])},
    "WORKING_CAPITAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonds de roulement"])},
    "FINANCIAL_SOLDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde financier"])},
    "DEBTS_SHORT_TERM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette courte terme / bilan"])},
    "DEBTS_MIDDLE_LONG_TERM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette moyen long terme / bilan"])},
    "NOT_AVAILABLE_NEED_UPLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liasse non disponible. Vous pouvez en charger une pour obtenir son analyse."])}
  },
  "FINANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance"])},
  "AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disponible"])},
  "UNAVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indisponible"])},
  "NO_AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non disponible"])},
  "YES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
  "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
  "MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mois"])},
  "EFFECTIVE_RECIPIENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bénéficiaire effectif"])},
  "EMPLOYEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employé"])},
  "REPRESENTATIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Représentant"])},
  "WELCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue"])},
  "LAST_UPDATE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scores mis à jour le"])},
  "VERIFICATION": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verif"])},
    "TOOLTIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification de l'existence de l'information avec le demandeur"])}
  },
  "RELATED": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lié"])},
    "TOOLTIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification de l'association de l'information avec le demandeur"])}
  },
  "FINISH": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloturer de dossier"])},
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhaitez vous récupérer la synthèse PDF avant de clôturer la page ?"])}
  },
  "FUNCTION": {
    "MANAGING_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur général"])}
  },
  "SOCIAL_NETWORKS": {
    "MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social networks linked to mail"])},
    "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social networks related to phone"])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social networks"])},
    "INFORMATIONS": {
      "AGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
      "CITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
      "STATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Région"])},
      "GENDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genre"])},
      "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
      "LANGUAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])}
    }
  },
  "TELEPHONE": {
    "LINETYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "TIMEZONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Région"])},
    "CARRIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opérateur actuel"])},
    "ORIGINAL_CARRIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operateur initial"])},
    "GEOLOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localisation"])},
    "OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétaire"])},
    "NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro"])}
  },
  "TABLE": {
    "PER_PAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par page"])},
    "FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier"])},
    "SUMMARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synthèse"])},
    "RULE": {
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "GLOBAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Globale"])},
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
      "VERSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
      "ACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
      "VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur"])},
      "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "DEFAULT_VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur par défaut"])}
    }
  },
  "RULES": {
    "RULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Règle"])},
    "CONFIG_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Config ID"])},
    "PARAMETERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
    "PARAMETER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre"])},
    "CURRENT_VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur actuelle"])},
    "NEW_VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle valeur"])},
    "ARGUMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arguments"])},
    "NO_PARAMETER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de paramètre"])},
    "NO_ARGUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas d'argument"])},
    "PARAMETERS_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des paramètres"])},
    "CONFIGURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
    "CREATE_THE_RULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer la règle"])},
    "GLOBAL_RULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Règle globale"])},
    "ADD_OPERATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une opération"])},
    "ADD_OPERAND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un opérande"])},
    "CHOOSE_SET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un ensemble de règles"])},
    "OR_CREATE_IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou créez-le"])},
    "CREATE_SET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un ensemble de règles"])},
    "SET_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'ensemble"])},
    "DELETE_SET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'ensemble"])},
    "ARE_YOU_SURE_TO_DELETE_SET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer l'ensemble"])},
    "FOR_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour l'entreprise"])},
    "THIS_ACTION_IS_IRREVERSIBLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette action est irréversible"])},
    "DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
    "REMOVE_RULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer une règle"])},
    "WARNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention"])},
    "ARE_YOU_SURE_TO_REMOVE_RULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir retirer la règle"])},
    "FROM_SET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de l'ensemble de règles"])},
    "ADD_A_RULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une règle"])},
    "SELECT_RULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez une règle"])},
    "RULE_V1_WARNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention ! C'est une règle V1, qui doit être initialisée pour l'entreprise, avec les bons paramètres."])},
    "INIT_RULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initialiser la règle"])},
    "PARAMETERS_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des paramètres à définir"])},
    "ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
    "MY_RULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ma-règle"])},
    "INITIALIZED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Règles initialisées"])},
    "ACTIVATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activée"])},
    "PARAMETER_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du paramètre"])},
    "PARAMETER_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description du paramètre"])},
    "PARAMETER_VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur du paramètre"])},
    "PARAMETER_NEW_VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle valeur du paramètre"])},
    "DEFAULT_VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur par défaut du paramètre"])},
    "VALUE_TO_INITIALIZE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur du paramètre à initialiser"])},
    "ADD_RULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter la règle"])},
    "AVAILABLE_RULES_TO_ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Règles disponibles à l'ajout"])},
    "NO_RULE_INITIALIZED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de règle initialisée"])},
    "BUTTON_ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
    "ADD_THE_RULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter la règle"])}
  },
  "USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])},
  "USER_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise de l'utilisateur"])},
  "COMPANY_SELECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise sélectionnée"])},
  "GLOBAL_USER_INFO": {
    "COMPANY_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'entreprise"])},
    "COMPANY_IDENTIFIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant de l'entreprise"])},
    "SELECTED_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise sélectionnée"])}
  },
  "SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])}
}