export default {
  "LOGIN_PAGE": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "ERROR_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The identifiers do not correspond to any account"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email"])},
    "PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password"])}
  },
  "CGS": {
    "BEGINNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By continuing, you agree the"])},
    "LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privacy policy"])},
    "END": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of Meelo"])}
  },
  "IDENTITY": {
    "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "FIRSTNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "BIRTHDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth"])},
    "PLACE_OF_BIRTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place of birth"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail address"])},
    "PHONE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile number"])},
    "LASTNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])}
  },
  "ADDRESS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "NUMBER_AND_STREET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street address"])},
    "ZIP_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip code"])},
    "CITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])}
  },
  "COMPANY": {
    "LIQUIDATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidation"])},
    "RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radiation"])},
    "SIREN_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIREN number"])},
    "INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company information"])},
    "CREATED_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of creation"])},
    "LEGAL_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corporate name"])},
    "CAPITAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share capital"])},
    "IDENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity of the company"])},
    "BUSINESS_OWNERS_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of managers"])},
    "BUSINESS_OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager"])},
    "MOTHBALLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mothballed"])},
    "CURRENT_PROCEDURES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current procedures"])},
    "E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-reputation"])},
    "GLOBAL_NOTE_E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overall rating"])},
    "ADVICE_NOTE_E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of reviews"])},
    "WEBSITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
    "NUMBER_ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of establishments"])},
    "REPRESENTATIVES_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of representatives"])},
    "RECOMMENDED_OUTSTANDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended amount outstanding"])},
    "TYPE_PERSON": {
      "NATURAL_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natural person"])},
      "LEGAL_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal person"])}
    },
    "FUNCTIONS": {
      "PRESIDENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["President"])},
      "CHAIRMAN_OF_THE_BOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chairman of the Board"])},
      "CHAIRMAN_AND_CHIEF_EXECUTIVE_OFFICER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chairman and Chief Executive Officer"])},
      "MANAGING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managing Director"])},
      "NON_ASSOCIATE_MANAGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-associate manager"])},
      "CO_MANAGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co-manager"])},
      "MANAGING_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managing Director"])},
      "GENERAL_MANAGER_NOT_A_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Manager not a director"])},
      "SOLE_MANAGING_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sole Managing Director"])},
      "CHIEF_OPERATING_OFFICER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chief Operating Officer"])},
      "VICE_CHAIRMAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vice-chairman"])},
      "DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Director"])},
      "DEPUTY_ADMINISTRATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managing Director"])},
      "CHAIRMAN_OF_THE_EXECUTIVE_DIRECTORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chairman of the Board of Directors"])},
      "CHAIRMAN_OF_THE_SUPERVISORY_BOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chairman of the Supervisory Board"])},
      "MANAGING_PARTNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managing partner"])},
      "CONTROLLER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controller"])},
      "STATUTORY_AUDITOR_TITULAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statutory auditor - titular"])},
      "STATUTORY_AUDITOR_DEPUTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statutory auditor - deputy"])},
      "STATUTORY_AUDITOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statutory auditor"])},
      "LIQUIDATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidator"])},
      "PROVISIONAL_ADMINISTRATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provisional administrator"])},
      "REPRESENTATIVE_IN_FRANCE_OF_A_FOREIGN_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representative in France of a foreign company"])},
      "MEMBER_OF_THE_BOARD_OF_DIRECTORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member of the Board of Directors"])},
      "CHAIRMAN_OF_THE_BOARD_OF_DIRECTORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chairman of the Board of Directors"])}
    },
    "TYPE": {
      "SOCIETY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Society"])},
      "COLLECTIVE_INVESTMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collective investment"])},
      "GIE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Economic interest group"])}
    }
  },
  "BUTTONS": {
    "VALIDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate"])},
    "VERIFY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify"])},
    "CONNEXION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
    "CONFIRM_INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm information"])},
    "DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "VERIFY_IDENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify identity"])},
    "START_OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Openbanking"])},
    "JOURNEY_OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do the Open Banking route on the browser"])},
    "FINISH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish"])},
    "EXPORT_PDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the summary"])},
    "PREVIOUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])}
  },
  "APPLICANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant"])},
  "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone"])},
  "VERIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verified"])},
  "MAIL_ACCOUNTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts linked to mail"])},
  "PHONE_ACCOUNTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone related accounts"])},
  "APPLICANT_RESULTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant's results"])},
  "CONTACT_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact information"])},
  "IDENTITY_CONSISTENCY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity consistency"])},
  "LINK_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link with the company"])},
  "OTHER_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other person"])},
  "SITUATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situation"])},
  "NOT_FOUND": {
    "NO_BUSINESS_OWNERS_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No beneficiaries recovered..."])},
    "NO_LEADERS_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No executives recovered..."])}
  },
  "OPEN_BANKING": {
    "END_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data has been successfully transmitted. You can close this page to see the result of the analysis."])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Banking"])},
    "DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Banking details"])},
    "BANK_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank"])},
    "INCOMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icomes"])},
    "OUTCOMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outcomes"])},
    "HOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holder"])},
    "ACCOUNT_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account name"])},
    "MONTH_SALARIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average monthly salary"])},
    "MONTH_RENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average monthly rent"])},
    "BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current balance"])},
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please ask your client to bring his smartphone and bank details"])},
    "CATEGORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "CATEGORIES": {
      "MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum amount of transactions"])},
      "MONTH_MAX_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of the maximum transaction"])},
      "MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average monthly total amount"])},
      "MONTH_SALARIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average monthly amount - Salaries"])},
      "MONTH_SALARIES_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average monthly date - Salaries"])},
      "MONTH_INTERESTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Monthly Amount - Interest"])},
      "MONTH_TRANSFERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Monthly Amount - Transfers"])},
      "MONTH_RENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average monthly amount - Rents"])},
      "MONTH_WITHDRAWALS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average monthly amount - ATM withdrawals"])},
      "MONTH_INSURANCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average monthly amount - Insurance"])},
      "MONTH_UTILITIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average monthly amount - Utilities"])},
      "MONTH_GROCERIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Monthly Amount - Food Purchases"])},
      "MONTH_FOOD_CONSUMPTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average monthly amount - Food consumption"])},
      "MONTH_SHOPPINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average monthly amount - Shopping"])},
      "MONTH_HEALTH_WELLNESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average monthly amount - Health/Wellness"])},
      "MONTH_TRANSPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average monthly amount - Transportation"])},
      "MONTH_TELECOMMUNICATION_SUBSCRIPTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average monthly amount - Subscriptions related to telecommunication services"])},
      "MONTH_AUDIO_VISUAL_SUBSCRIPTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average monthly amount - Subscriptions related to audio-visual services"])}
    },
    "DEBT_RATIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debt Ratio"])},
    "REMAINS_TO_LIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Living expenses"])},
    "START_STUDY_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date of the financial analysis period"])},
    "END_STUDY_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date of the financial analysis period"])},
    "MONTH_END_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly balance"])},
    "TOP_JOINT_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Joint Account"])},
    "TOP_RECOMMENDED_DATE_DEBIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended date of debit"])},
    "TOP_BANKING_SUPERVISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banking supervision"])},
    "GLOBAL_TRANSACTIONS_COUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of transactions"])}
  },
  "SOCIAL_FACE_MATCHING": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social FaceMatching"])},
    "CATCHED_IMAGES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrieved images"])}
  },
  "AMLCFT": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-Money Laundering and Countering the Financing of Terrorism"])},
    "FREEZING_ASSETS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset freeze"])},
    "PPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person politically exposed"])},
    "ADVERSE_MEDIA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adverse media"])},
    "COLUMN_TABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMLCFT"])}
  },
  "TOOLTIP": {
    "CURRENT_PROCEDURES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company with pending legal proceedings."])},
    "MOTHBALLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company placed dormant and since reactivated."])},
    "MOBILE_PHONE_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile phone verification."])},
    "SOCIAL_FACE_MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matching between the images available online and the ID."])},
    "AVALIABLES_PICTURES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images available on social networks against the applicant's information."])},
    "NUMBER_ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of establishments owned by the company."])},
    "FREEZING_ASSETS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes if there is a current asset freeze."])},
    "SCORE_WEB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraud Scoring on the existence and reliability of the applicant's contact data."])},
    "SCORE_SECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risk Scoring on the financial analysis and solvency of the company."])},
    "DEFAULT_PROBABILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesure company default probability."])},
    "SCORE_LAST_UPDATE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last scores update"])},
    "SCORE_OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risk Scoring on the financial analysis of the bank account."])},
    "MONTH_END_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average monthly balance over the period studied."])}
  },
  "DOCUMENT_CONTROL": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control documents"])},
    "CHOOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the document you want to upload:"])},
    "IDENTITY_CARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID card"])},
    "DRIVING_LICENSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver's license"])},
    "PASSPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport"])},
    "BLOC_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Control"])},
    "ERROR_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The analysis of the part failed"])},
    "TABLE": {
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document"])},
      "COMPLIANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compliance"])},
      "RESULT": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis results of your document"])},
        "CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification"])},
        "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results"])}
      }
    }
  },
  "SCORE": {
    "SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
    "WEB_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web"])},
    "SECTOR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sector"])},
    "COMPANY_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "DEFAULT_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])}
  },
  "FINANCIAL_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial analysis"])},
  "TAX_REPORTS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Report"])},
    "FISCAL_PERIOD_DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiscal Period"])},
    "CLOSURE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closing date"])},
    "TURNOVER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turnover"])},
    "NET_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net income"])},
    "AUTO_FINANCE_CAPACITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self-financing capacity"])},
    "NET_MARGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net margin"])},
    "FB_TB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial structure"])},
    "FINANCIAL_LIQUIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Availability"])},
    "WORKING_CAPITAL_REQUIREMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working capital requirement"])},
    "WORKING_CAPITAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working capital"])},
    "FINANCIAL_SOLDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial balance"])},
    "DEBTS_SHORT_TERM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short term debt / balance sheet"])},
    "DEBTS_MIDDLE_LONG_TERM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medium and long term debt / balance sheet"])},
    "NOT_AVAILABLE_NEED_UPLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax report unavaliable.\nYan can upload one to get analysis."])}
  },
  "FINANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance"])},
  "AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available"])},
  "UNAVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unavailable"])},
  "NO_AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not available"])},
  "YES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["month"])},
  "EFFECTIVE_RECIPIENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beneficiary"])},
  "EMPLOYEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee"])},
  "REPRESENTATIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representative"])},
  "WELCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
  "LAST_UPDATE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update date"])},
  "VERIFICATION": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verif"])},
    "TOOLTIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification of the existence of the information with the applicant"])}
  },
  "RELATED": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related"])},
    "TOOLTIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification of the the information association with the applicant"])}
  },
  "FINISH": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close folder"])},
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wish you get PDF report before close page ?"])}
  },
  "FUNCTION": {
    "MANAGING_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managing director"])}
  },
  "SOCIAL_NETWORKS": {
    "MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social networks linked to mail"])},
    "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social networks related to phone"])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social networks"])},
    "INFORMATIONS": {
      "AGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
      "CITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "STATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
      "GENDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
      "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "LANGUAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])}
    }
  },
  "TELEPHONE": {
    "LINETYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linetype"])},
    "TIMEZONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
    "CARRIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current operator"])},
    "ORIGINAL_CARRIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initial operator"])},
    "GEOLOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
    "NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])}
  },
  "TABLE": {
    "PER_PAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per page"])},
    "FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folder"])},
    "SUMMARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
    "RULE": {
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "GLOBAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global"])},
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
      "VERSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
      "ACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])}
    }
  },
  "RULES": {
    "INITIALIZED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rules initialized"])},
    "ACTIVATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activated"])},
    "PARAMETER_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter name"])},
    "PARAMETER_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter description"])},
    "PARAMETER_VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter value"])},
    "PARAMETER_NEW_VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New parameter value"])},
    "DEFAULT_VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default parameter value"])},
    "VALUE_TO_INITIALIZE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter value to initialize"])},
    "ADD_RULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add rule"])},
    "AVAILABLE_RULES_TO_ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rules available for addition"])},
    "NO_RULE_INITIALIZED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No rule initialized"])},
    "BUTTON_ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "ADD_THE_RULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add the rule"])}
  },
  "GLOBAL_USER_INFO": {
    "COMPANY_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company name"])},
    "COMPANY_IDENTIFIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company identifier"])},
    "SELECTED_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected company"])}
  }
}