
import MeeloHeader from "@/components/Header.vue";
import {defineComponent} from "vue";

export default defineComponent({
  components: {MeeloHeader},
  created(){
    if (!this.$cookies.get('access_token') && window.location.pathname !== '/open-banking-end'){
      this.$router.push('/');
    }
  },
})
